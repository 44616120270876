import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { isAxiosError } from 'axios'

import { CreateLoyaltyCardCustomizationContract, ErrorContract, ValidationErrorContract } from '@/types/api'
import { Dispatch, RootState, store } from '@/utilities/store'
import Form, { FormInputData } from '@/components/Form'
import { setFormErrors } from '@/utilities/functions'
import { selectCustomizationsObj, selectHasUploadedImg } from '@/models/customizations'
import { CustomizationImgs } from '@/types'

export const INITIAL_FORM_VALUES: CustomizationFormValues = {
  isDirectQR: false,
  appleLogoUrl: null,
  appleStripImageUrl: null,
  backgroundColor: '',
  email: '',
  emailSenderName: '',
  googleLogoUrl: null,
  googleStripImageUrl: null,
  name: '',
  organizationName: '',
  phoneNumber: '',
  webpage: '',
  font: '',
  walletCardTitle: '',
  validForMonths: 1,
  firstHeaderText: 'Sveiki, siunčiame Jūsų kliento kortelę',
  firstBodyText:
    'Kliento kortele galite naudotis {{CardTitle}} restorane ir atrasti patogesnį būdą susimokėti už savo pamėgtus patiekalus ir pasinaudoti nuolaidomis! Kortelę įtraukite į Apple Pay ar Google Pay ir iškart pradėkite naudotis jos privalumais! Peržiūrėti galite atsidarę nuorodą arba nuskenavę QR kodą.',
  firstViewCardLinkText: 'Nuoroda',
  secondHeaderText: 'Hey, here’s your new client card',
  secondBodyText:
    'You can use your client card at the {{CardTitle}} and discover an easy way to pay for your favorite meals and use your discounts!Add the card to your Apple Pay or Google Pay and start using it right away! You can view it by opening this link or by scanning a QR code below.',
  secondViewCardLinkText: 'Link',
  bottomBodyText:
    'Kortelę išdavusios įmonės pavadinimas: {{OrganizationTitle}}. Kontaktinis el. pašto adresas: {{OrganizationEmail}}',
  footerText:
    'Ši kortelė priklauso {{OrganizationTitle}} ir yra skirta {{ClientEmail}}, todėl jei ji pasiekė neteisingą adresatą informuokite nurodytu el. pašto adresu ir ištrinkite šį laišką. Dėkojame.',
  creditsLeftText: 'Likę kreditų:',
}

const customizationSchema: yup.ObjectSchema<CustomizationFormValues> = yup
  .object({
    isDirectQR: yup.boolean().label('Show Loyalty QR Code in email'),
    id: yup.string(),
    appleLogoUrl: yup
      .mixed()
      .nullable()
      .when('id', ([customizationId], schema) =>
        selectHasUploadedImg(store.getState(), { customizationId, name: 'appleLogoUrl' }) ? schema : schema.required(),
      )
      .label('Apple logo image'),
    appleStripImageUrl: yup
      .mixed()
      .nullable()
      .when('id', ([customizationId], schema) =>
        selectHasUploadedImg(store.getState(), { customizationId, name: 'appleStripImageUrl' })
          ? schema
          : schema.required(),
      )
      .label('Apple strip image'),
    backgroundColor: yup.string().label('Background color'),
    email: yup.string().email().required().label('Email'),
    emailSenderName: yup.string().label('Email sender name'),
    googleLogoUrl: yup
      .mixed()
      .nullable()
      .when('id', ([customizationId], schema) =>
        selectHasUploadedImg(store.getState(), { customizationId, name: 'googleLogoUrl' }) ? schema : schema.required(),
      )
      .label('Google logo image'),
    googleStripImageUrl: yup
      .mixed()
      .nullable()
      .when('id', ([customizationId], schema) =>
        selectHasUploadedImg(store.getState(), { customizationId, name: 'googleStripImageUrl' })
          ? schema
          : schema.required(),
      )
      .label('Google strip image'),
    name: yup.string().label('Name').required(),
    organizationName: yup.string().label('Organization name').required(),
    phoneNumber: yup.string().label('Phone number'),
    webpage: yup.string().url().label('Webpage'),
    font: yup.string().label('Font'),
    walletCardTitle: yup.string().label('Wallet card title'),
    validForMonths: yup.number().min(1).max(36).label('Validity period in months'),
    firstHeaderText: yup.string().label('First header text'),
    firstBodyText: yup.string().label('First body text'),
    firstViewCardLinkText: yup.string().label('First view card link text'),
    secondHeaderText: yup.string().label('Second header text'),
    secondBodyText: yup.string().label('Second body text'),
    secondViewCardLinkText: yup.string().label('Second view card link text'),
    bottomBodyText: yup.string().label('Bottom body text'),
    footerText: yup.string().label('Footer text'),
    creditsLeftText: yup.string().label('Credits left text'),
  })
  .required()

interface CustomizationDialogProps {
  open: boolean
  selectedCustomizationId?: string
  onCreate?: (
    data: CreateLoyaltyCardCustomizationContract,
  ) => Promise<{ errors: ValidationErrorContract[] } | undefined>
  onUpdate?: (data: {
    customizationId: string
    data: CreateLoyaltyCardCustomizationContract
  }) => Promise<{ errors: ValidationErrorContract[] } | undefined>
  onClose?: () => void
  onDelete?: (customizationId: string) => Promise<void>
}

type CustomizationFormValues = {
  [Property in keyof Omit<
    CreateLoyaltyCardCustomizationContract,
    CustomizationImgs | 'validForMonths' | 'isDirectQR'
  >]: string
} & {
  id?: string
  appleLogoUrl?: any
  appleStripImageUrl?: any
  googleLogoUrl?: any
  googleStripImageUrl?: any
  validForMonths?: number
  isDirectQR?: boolean
}

interface CustomizationFormData extends FormInputData {
  name: keyof CreateLoyaltyCardCustomizationContract
}

const CustomizationDialog = ({
  open,
  selectedCustomizationId,
  onCreate,
  onUpdate,
  onClose,
  onDelete,
}: CustomizationDialogProps) => {
  const [loading, setLoading] = useState<boolean>(false)

  const fonts = useSelector((state: RootState) => state.fonts.fonts)

  const customizationsObj = useSelector(selectCustomizationsObj)

  const dispatch = useDispatch<Dispatch>()

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setError,
  } = useForm<CustomizationFormValues>({
    resolver: yupResolver(customizationSchema),
  })

  const watchAllFields = watch()

  const customization = useMemo(() => {
    if (!selectedCustomizationId) {
      return
    }

    return customizationsObj[selectedCustomizationId]
  }, [customizationsObj, selectedCustomizationId])

  const getImg = useCallback(
    (name: CustomizationImgs) => {
      const localImg = watchAllFields[name]
      if (localImg) {
        return URL.createObjectURL(localImg)
      }

      const storedImg = customization?.[name]
      if (!storedImg) {
        return
      }

      return `${process.env.VITE_APP_IMGIX_URL}/${storedImg}`
    },
    [customization, watchAllFields],
  )

  const formInputsData = useMemo(
    (): CustomizationFormData[] => [
      {
        type: 'switch',
        name: 'isDirectQR',
        label: 'Show Loyalty QR Code in email',
        helperText: '*If disabled, we display a QR code that redirects to the loyalty card page',
      },
      {
        type: 'file',
        name: 'appleLogoUrl',
        label: 'Apple logo image (480 x 150)',
        imgSrc: getImg('appleLogoUrl'),
      },
      {
        type: 'file',
        name: 'googleLogoUrl',
        label: 'Google logo image (660 x 660)',
        imgSrc: getImg('googleLogoUrl'),
      },
      {
        type: 'text',
        name: 'organizationName',
        label: 'Organization name',
      },
      {
        type: 'text',
        name: 'name',
        label: 'Name',
      },
      {
        type: 'file',
        name: 'appleStripImageUrl',
        label: 'Apple strip image (1125 x 432)',
        imgSrc: getImg('appleStripImageUrl'),
      },
      {
        type: 'file',
        name: 'googleStripImageUrl',
        label: 'Google strip image (1032 x 336)',
        imgSrc: getImg('googleStripImageUrl'),
      },
      {
        type: 'text',
        name: 'phoneNumber',
        label: 'Phone number',
      },
      {
        type: 'text',
        name: 'email',
        label: 'Email',
      },
      {
        type: 'text',
        name: 'emailSenderName',
        label: 'Email sender name',
      },
      {
        type: 'text',
        name: 'webpage',
        label: 'Webpage (URL)',
      },
      {
        type: 'color',
        name: 'backgroundColor',
        label: 'Background color',
      },
      {
        type: 'select',
        name: 'font',
        label: 'Font',
        items: fonts.map((font) => ({ value: font.title ?? '', label: font.title ?? '' })),
      },
      {
        type: 'text',
        name: 'walletCardTitle',
        label: 'Wallet card title',
      },
      {
        type: 'text',
        name: 'validForMonths',
        label: 'Validity period in months',
        inputType: 'number',
      },
      {
        type: 'text',
        name: 'firstHeaderText',
        label: 'First header text',
        multiline: true,
      },
      {
        type: 'text',
        name: 'firstBodyText',
        label: 'First body text',
        multiline: true,
      },
      {
        type: 'text',
        name: 'firstViewCardLinkText',
        label: 'First view card link text',
        multiline: true,
      },
      {
        type: 'text',
        name: 'secondHeaderText',
        label: 'Second header text',
        multiline: true,
      },
      {
        type: 'text',
        name: 'secondBodyText',
        label: 'Second body text',
        multiline: true,
      },
      {
        type: 'text',
        name: 'secondViewCardLinkText',
        label: 'Second view card link text',
        multiline: true,
      },
      {
        type: 'text',
        name: 'bottomBodyText',
        label: 'Bottom body text',
        multiline: true,
      },
      {
        type: 'text',
        name: 'footerText',
        label: 'Footer text',
        multiline: true,
      },
      {
        type: 'text',
        name: 'creditsLeftText',
        label: 'Credits left text',
        multiline: true,
      },
    ],
    [fonts, getImg],
  )

  const uploadImages = async (imgs?: { [key: string]: File | null | undefined }) => {
    const obj: { [key: string]: string } = {}
    for (const [key, file] of Object.entries(imgs ?? {})) {
      if (!file) {
        continue
      }

      try {
        const { value } = await dispatch.images.uploadImage(file)
        if (!value) {
          continue
        }

        obj[key] = value
      } catch (error) {
        console.error(error)

        if (!isAxiosError<ErrorContract>(error)) {
          continue
        }

        alert(error.response?.data.message)
      }
    }

    return obj
  }

  const onSubmit: SubmitHandler<CustomizationFormValues> = async (formData) => {
    setLoading(true)

    const { appleLogoUrl, appleStripImageUrl, googleLogoUrl, googleStripImageUrl, ...mappedFormData } =
      Object.fromEntries(
        Object.entries(formData).map(([key, value]) => {
          if (!value) {
            return [key, null]
          }

          return [key, value]
        }),
      ) as CustomizationFormValues
    delete mappedFormData.id

    const uploadedImages = await uploadImages({ appleLogoUrl, appleStripImageUrl, googleLogoUrl, googleStripImageUrl })

    if (customization) {
      const images = {
        appleLogoUrl: customization.appleLogoUrl,
        appleStripImageUrl: customization.appleStripImageUrl,
        googleLogoUrl: customization.googleLogoUrl,
        googleStripImageUrl: customization.googleStripImageUrl,
        ...uploadedImages,
      }

      const res = await onUpdate?.({
        customizationId: customization.id!,
        data: { ...mappedFormData, ...images } as CreateLoyaltyCardCustomizationContract,
      })

      setFormErrors(res?.errors, setError)

      setLoading(false)

      return
    }

    const res = await onCreate?.({ ...mappedFormData, ...uploadedImages } as CreateLoyaltyCardCustomizationContract)

    setFormErrors(res?.errors, setError)

    setLoading(false)
  }

  const handleDelete = async () => {
    if (!customization || !window.confirm('Are you sure you want to delete this customization?')) {
      return
    }

    setLoading(true)

    await onDelete?.(customization.id!)

    setLoading(false)
  }

  useEffect(() => {
    if (!open) {
      return
    }

    if (!customization) {
      reset(INITIAL_FORM_VALUES)
      return
    }

    const mappedCustomization = Object.fromEntries(
      Object.entries(customization).map(([key, value]) => {
        if (!value) {
          if (key === 'validForMonths') {
            return [key, 0]
          }

          return [key, '']
        }

        return [key, value]
      }),
    ) as CustomizationFormValues

    reset({
      ...mappedCustomization,
      appleLogoUrl: INITIAL_FORM_VALUES.appleLogoUrl,
      appleStripImageUrl: INITIAL_FORM_VALUES.appleStripImageUrl,
      googleLogoUrl: INITIAL_FORM_VALUES.googleLogoUrl,
      googleStripImageUrl: INITIAL_FORM_VALUES.googleStripImageUrl,
      firstHeaderText: customization.firstHeaderText ?? INITIAL_FORM_VALUES.firstHeaderText,
      firstBodyText: customization.firstBodyText ?? INITIAL_FORM_VALUES.firstBodyText,
      firstViewCardLinkText: customization.firstViewCardLinkText ?? INITIAL_FORM_VALUES.firstViewCardLinkText,
      secondHeaderText: customization.secondHeaderText ?? INITIAL_FORM_VALUES.secondHeaderText,
      secondBodyText: customization.secondBodyText ?? INITIAL_FORM_VALUES.secondBodyText,
      secondViewCardLinkText: customization.secondViewCardLinkText ?? INITIAL_FORM_VALUES.secondViewCardLinkText,
      bottomBodyText: customization.bottomBodyText ?? INITIAL_FORM_VALUES.bottomBodyText,
      footerText: customization.footerText ?? INITIAL_FORM_VALUES.footerText,
      creditsLeftText: customization.creditsLeftText ?? INITIAL_FORM_VALUES.creditsLeftText,
      isDirectQR: !!customization.isDirectQR,
    })
  }, [customization, open, reset])

  return (
    <Dialog fullWidth open={open} maxWidth="md" onClose={onClose}>
      <DialogTitle>{customization ? 'Update card customization' : 'Create card customization'}</DialogTitle>

      <DialogContent>
        <Box py={2}>
          <Form control={control} errors={errors} formInputsData={formInputsData} loading={loading} />
        </Box>
      </DialogContent>

      <DialogActions disableSpacing>
        <Box display="flex" columnGap={1} flex={1}>
          {!!customization && (
            <Button type="button" color="error" disabled={loading} onClick={handleDelete}>
              Delete
            </Button>
          )}

          <Box display="flex" columnGap={1} flex={1} justifyContent="end">
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>

            <Button type="button" disabled={loading} onClick={handleSubmit(onSubmit)}>
              {customization ? 'Update' : 'Create'}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default CustomizationDialog
